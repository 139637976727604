import React from "react";
import { Link } from "gatsby-plugin-intl";
import { HighlightedText } from "@userbrain/website-ui";

import Layout from "../../layouts/layout";
import { GetStartedBox } from "../../components/GetStartedBox";
import { HeaderCompare } from "../../components/HeaderCompare";
import { HtmlContent } from "../../components/HtmlContent";
import { Testimonials } from "../../components/Testimonials";
import {
  testimonialThomas,
  testimonialJoshua,
  useLocalCurrencySymbol,
  testimonialPaul,
  usePrices,
} from "../../helpers";
import * as helperStyles from "../helpers.module.css";
import * as styles from "./compare.module.css";

import imageDashboardSessions from "../../images/compareDashboardSessions.jpg";
import { CompareLastParagraph } from "../../components/helpers/CompareLastParagraph";

const ContentPage = () => {
  const currencySymbol = useLocalCurrencySymbol();

  const { minSubscriptionPricePerMonth } = usePrices();

  return (
    <Layout
      title={"Userlytics vs. Userbrain"}
      metaDescription={
        "Seeking the perfect user testing tool can be a challenge. With numerous options on the market, each with its unique strengths, how can you decide which one best fits your needs?"
      }
    >
      <article>
        <HeaderCompare
          title={
            <>
              <span className={helperStyles.h1Serif}>Userlytics vs.</span>{" "}
              Userbrain
            </>
          }
          content={
            "Seeking the perfect user testing tool can be a challenge. With numerous options on the market, each with its unique strengths, how can you decide which one best fits your needs? Understanding your predicament, we’ve made it easier for you to make an informed choice. Here, we present six distinctive areas where Userbrain differentiates itself from Userlytics. Our aim is to empower you with knowledge to choose a tool that’s not just affordable, but also efficient, intuitive, and tailored to your business requirements."
          }
          imageSrc={imageDashboardSessions}
          imageAlt={
            "Userbrain dashboard showing a list of remote user testing videos"
          }
        />
        <div className={helperStyles.container}>
          <HtmlContent className={styles.htmlContent}>
            <h2>1. Cost Efficiency with Userbrain</h2>
            <p>
              Userbrain offers you pricing clarity right from the start. With{" "}
              <Link to={"/pricing"}>
                plans starting at just {currencySymbol}
                {minSubscriptionPricePerMonth}
              </Link>{" "}
              per month, and discounts offered for annual billing, we ensure
              high quality testing that respects your budget. Whether you’re
              looking for a subscription, or pay-as-you-go, Userbrain has you
              covered. While Userlytics also offers variable pricing, the clear
              and upfront pricing model of Userbrain removes any need for cost
              calculation.
            </p>

            <h2>2. Simplicity and Transparency</h2>
            <p>
              Where Userbrain truly shines is in its simple, straightforward
              approach. From setting up a user test to understanding your
              pricing, Userbrain ensures a seamless experience. While Userlytics
              has tools to calculate costs based on your budget, the pricing and
              process are straightforward from the outset with Userbrain.
            </p>

            <h2>3. Flexible Credit System</h2>
            <p>
              With Userbrain, you need not worry about losing out on your
              credits. Unused credits simply roll over to the next month,
              ensuring you maximize the value of your investment. On the other
              hand, Userlytics credits operate differently depending on the
              plan. With Userlytics, ad-hoc user test credits expire 6 months
              after the date of purchase, while Enterprise user test credits
              expire 12 months from the date of the plan purchase or renewal.
            </p>

            <h2>4. Unlimited Test Video Access</h2>
            <p>
              Userbrain guarantees long-term access to your user test videos –
              up to 12 months for Starter and Pro plans, and unlimited for
              Agency and Enterprise plans. In comparison, Userlytics stores
              videos for at least six months, and often longer. With extended
              accessibility, particularly for our premium plans, Userbrain
              ensures your user insights are there when you need them.
            </p>

            <h2>5. Comprehensive User Testing Features & AI Insights</h2>
            <p>
              Userbrain offers a set of comprehensive user testing features,
              including our{" "}
              <a href="https://www.userbrain.com/blog/automated-insights-streamline-user-test-analysis-with-ai-assistance">
                AI Insights
              </a>
              . This feature streamlines your analysis process by examining
              every user test transcript, generating time-stamped notes and
              insights. This saves you considerable time, as you won't need to
              stop videos to make notes manually. All these insights are
              integrated into your report automatically, further simplifying
              your process.
            </p>
            <p>
              Userlytics also incorporates AI capabilities, to some extent, with
              their sentiment analysis feature, underlining the importance of
              advanced technology in making user testing more efficient.
            </p>

            <h2>6. Regular & Automated User Testing</h2>
            <p>
              At Userbrain, we understand the importance of regular testing. Our
              platform allows you to schedule tests to run at intervals that
              suit your timeline. Want a test to run once a month? Simply set it
              to repeat monthly. We focus on automating the process, leaving you
              to concentrate on the valuable insights generated.
            </p>
            <p>
              Userbrain works tirelessly to make user testing easy, fast, and
              affordable, offering the best possible value for your time and
              investment.
            </p>

            <CompareLastParagraph />
          </HtmlContent>
        </div>
        <div className={helperStyles.container}>
          <Testimonials
            testimonials={[
              testimonialJoshua,
              testimonialThomas,
              testimonialPaul,
            ]}
          />
          <GetStartedBox
            className={helperStyles.spacingLg}
            title={
              <>
                Ready for your first{" "}
                <HighlightedText cursor>user test?</HighlightedText>
              </>
            }
            textBottom={
              "Get set up in minutes. Your first test is ready in a few hours."
            }
          />
        </div>
      </article>
    </Layout>
  );
};

export default ContentPage;
